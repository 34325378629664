/*jshint esversion: 6 */

var template = document.body.getAttribute('data-intended-template');

//menu 
var toggle = document.querySelector('#togglemenu');
var header = document.querySelector('#header');
toggle.addEventListener('click', function(){
  header.classList.toggle('opened');
  document.body.classList.toggle('menu-opened');
});

var scrollPos = 0;
// adding scroll event
var scrollHeader = debounce(function() {
	// All the taxing stuff you do
  var  rect = (document.body.getBoundingClientRect()).top;
  // detects new state and compares it with the new one
  if ((document.body.getBoundingClientRect()).top > scrollPos)
		header.setAttribute('data-scroll-direction', 'UP');
	else
		header.setAttribute('data-scroll-direction', 'DOWN');
	// saves the new position for iteration.
	scrollPos = (document.body.getBoundingClientRect()).top;
}, 250);

window.addEventListener('scroll', scrollHeader);

// debounce utility
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

//home 
var focus = document.querySelector('.fokus');
function move(){
  focus.classList.add('homeloaded');
  window.removeEventListener('mousemove', move);
}
if(focus){  
  setTimeout(function(){
    window.addEventListener('mousemove', move);
  }, 2000);
}

//search 
var searchbutton = document.querySelector('#search-navbutton');
var search = document.querySelector('#search-button');
var searchoverlay = document.querySelector('#search-overlay');
if(searchbutton){  
  searchbutton.addEventListener('click', function(){
    var bar = document.querySelector("#search-bar");
    var input = document.querySelector("#search-input");
    bar.classList.add('opened');
    header.classList.add('searching');
    input.focus();
    input.select();
    searchoverlay.addEventListener('click', function(){
      bar.classList.remove('opened');
      header.classList.remove('searching');
    });
    search.addEventListener('click', function(){
      if(input.value == "") {
        bar.classList.remove('opened');
        header.classList.remove('searching');
      }
    });
  });
}


// book
function changeslideshowClass(slideshow){
  if(window.pageYOffset > 0){
    slideshow.classList.add("scrolled");
  } else {
    slideshow.classList.remove("scrolled");
  }
}
if(template == "book"){
  var slideshow = document.querySelector('.book-slideshow');
  var scrolled = false;  
  
  if(slideshow){
    changeslideshowClass(slideshow);
    window.onscroll = function(e) {
      if(!scrolled) changeslideshowClass(slideshow);
    };
  }

  // Arabic Hasard 
  var book = document.querySelector('main.book');
  if(book.id == "book-74") {
    var title = book.querySelector('.book-title h1');
    var a = title.textContent.split("Hasard");
    title.innerHTML = a[0] + "Hasard <br>" + a[1];
  }

  //slideshow
  var slideshows = document.querySelectorAll('.book-slideshow');
  slideshows.forEach(function(slideshow){
    var flkty = new Flickity( slideshow, {
      cellAlign: 'center',
      contain: true,      
      draggable: '>1',
      pageDots: false,
      imagesLoaded: true,
      wrapAround: true
    });
    
    if(document.querySelector('.carousel-cell').matches('.is-dark, .has_background')){
      slideshow.classList.add('is-dark');
    }
    flkty.on( 'change', function( index ) {
      if(flkty.selectedElement.matches('.is-dark, .has_background')){
        slideshow.classList.add('is-dark');
      } else {
        slideshow.classList.remove('is-dark');
      }
    });
  });

}


// lancer de dés

var des = document.querySelector('.des');
var related_container = null, related_list = null, related_initial_height = 0, new_related_nodes = [];
function roll(){
  var size = 20;
  document.querySelector('.des').style.setProperty('--size', size + "px");
  document.querySelectorAll('.de').forEach( function(d) {
    var rot = parseInt(d.style.getPropertyValue('--rot')) || 0;
    var pos = -1 * Math.floor( Math.random() * 6) * size;
    d.style.setProperty('--pos', "50% " + pos + 'px');
    var newrot = Math.floor( Math.random() * 360 * 1.5);
    rot = rot + newrot;
    d.style.setProperty('--rot', rot + 'deg');
    d.style.setProperty('--oldrot', rot + 'deg');
    d.style.setProperty('--duration', newrot / 1.25 + 'ms'); 
    // var top = Math.floor( Math.random() * size / 1.5);
    // d.style.setProperty('--top', top + 'px');   
  });
}
function hideRelated(){
  return new Promise( (resolve, reject) => {
    related_list = related_container.querySelectorAll('.booklink');
    related_initial_height = related_container.outerHeight;
    related_container.style.height = related_initial_height + "px";
    related_container.classList.add('hidden');
    setTimeout(function(){
      resolve();
    }, 4 * 200);
  });
}
function removeRelated(){
  return new Promise( (resolve, reject) => {
    related_list.forEach( (e) => {
      related_container.removeChild(e);
    });
    resolve();
  });
}
function buildRelated(articles){
  return new Promise( (resolve, reject) => {
    articles.forEach(article => {
      var a = document.createElement('a');
      a.href = article.url;
      a.className = "booklink small";
      a.style.setProperty('--h', article.h);
      a.style.setProperty('--w', article.w);
      a.style.background = article.background;
      var f = document.createElement("figure");
      f.className = "cover";
      var i = document.createElement("img");
      i.src = article.cover;
      f.appendChild(i);
      a.appendChild(f);    
      new_related_nodes.push(a);
    });
    resolve();
  });
}
function showRelated(){
  new_related_nodes.forEach( function(e){
    related_container.appendChild(e);
  });
  new_related_nodes = [];
  related_list = related_container.querySelectorAll('.booklink');
  related_initial_height = 0;
  related_list.forEach( (e) => {
    related_initial_height = Math.max(related_initial_height, e.outerHeight);
  });
  setTimeout(() => {
    related_container.style.height = related_initial_height + "px";
    setTimeout(() => {
      related_container.classList.remove('hidden');
    }, 200); 
  }, 200); 
  
}

if(des){
  roll();
  related_container = document.querySelector('.random');
  
  des.addEventListener('click', function(){
    roll();
    fetch(window.SITE_URL + '/random.json?' + Math.random())
    .then(function(response) {
      return response.json();
    })
    .then(function(articles) {
      return buildRelated(articles);
    })
    .then(function() {
     return hideRelated();
    })
    .then(function() {
     return removeRelated();
    })
    .then(function() {
      showRelated();
    });
  });

  var dices = des.querySelectorAll('.de');
  des.addEventListener('mouseover', function(e){
    dices.forEach(function(d){
      var rot = parseInt(d.style.getPropertyValue('--rot')) || 0;
      var newrot = rot - (Math.random() * 40 + 30);
      d.style.setProperty('--duration', '300ms'); 
      d.style.setProperty('--rot', newrot + 'deg');
      d.style.setProperty('--oldrot', rot + 'deg');
      d.dataset.rot = rot;
    });
  });
  des.addEventListener('mouseout', function(e){
    dices.forEach(function(d){
      var rot = parseInt(d.style.getPropertyValue('--rot')) || 0;
      var oldrot = parseInt(d.style.getPropertyValue('--oldrot')) || 0;
      if(oldrot == rot) return;
      d.style.setProperty('--rot', oldrot + 'deg');
    });
  });
}


// snipcart
document.addEventListener('snipcart.ready', function() {
  var cart = document.querySelector("#snipcart-cart");
  Snipcart.events.on('item.added', function(cartItem) {
    cart.classList.add('visible');
  });
  Snipcart.events.on('item.removed', function(cartItem) {
    if(Snipcart.api.cart.items.count > 0){
      cart.classList.add('visible');
    } else {
      cart.classList.remove('visible');
    }
  });
  Snipcart.events.on('snipcart.initialized', function(snipcartState) {
    if(snipcartState.cart.items.count > 0){
      cart.classList.add('visible');
    }
  });
});


if(template == "news" || template == "new" ){
  
  var headers = document.querySelectorAll('.ephemera header');
  
  if(news_url === undefined) news_url = "https://ypsilonediteur.com/ephemeride/";
  
  headers.forEach( function(header){
    header.addEventListener('click', function(){
      var article = header.closest('article'),
        is_active = article.classList.contains('open');
      if(is_active){
        article.classList.remove('open');
      } else {
        var loc = news_url + "/#"+ article.id;
        var url = article.dataset.url;
        if(article.classList.contains('press-detail')){
          url = news_url + "/#" + url;
        }
        history.pushState({loc:loc, hash:article.id, ephemera:true}, document.title, url);
        openNewsArticle(article);
      }
    });
  });
  // we have a news to open
  if(page_slug != ""){
    var article = document.querySelector("#" + page_slug);
    if(article) openNewsArticle(article);
  }
  // we have a press to open
  if(window.location.hash != ""){
    var article = document.querySelector("#♥-" + window.location.hash.replace('#', ''));
    if(article) openNewsArticle(article);
  }
}

function openNewsArticle(article){
  if(article){
    var opened = document.querySelectorAll('.open');
    opened.forEach(function(art){
      art.classList.remove('open');
    });          
    setTimeout(function(){
      article.scrollIntoView();
    }, 100);
    setTimeout(function(){
      article.classList.add('open');            
    }, 300);
  }
}

window.onpopstate = function(event) {
  
  if(event.state){
    event.preventDefault();
    if(event.state.ephemera == true && event.state.hash != ""){
      var article = document.querySelector("#" + event.state.hash);
      openNewsArticle(article); 
    }
    if(event.state.ephemera == true && event.state.hash == ""){
      var futures = document.querySelectorAll('.future');
      futures.forEach(function(art){
        window.scrollTo({top: 0, behavior: 'smooth'});
        art.classList.add('open');
      });
    }
    if(event.state.ephemera == true ){}
  }
};

// author
if(template == "author"){
  var portrait = document.querySelector('.portrait');
  var text = document.querySelector('.text');
  var p = text.querySelector("p:nth-of-type(2)");
  if(portrait)  p.parentElement.insertBefore(portrait, p);
}
